<template>
	<v-container class="fill-height pa-0" style="flex-direction: column; justify-content: center" fluid>
		<div v-if="ready" class="fill-height d-flex" style="flex-direction: column; justify-content: center">
			<v-card max-width="320" min-width="320" min-height="450" max-height="450" class="rounded-lg fill-height overflow-hidden" :class="embed ? 'transparent':''" :flat="embed">
				<v-card-title class="primary white--text pa-3">
					<v-icon dark class="mr-4" size="18">mdi-account-plus</v-icon>
					<div class="sub-title-2">{{ $tc("auth.Login.register", 1) }}</div>
					<v-spacer v-if="embed" />
					<v-icon v-if="embed" @click="$emit('close')" dark class="mr-1" size="18">mdi-close</v-icon>
				</v-card-title>
				<div style="height: calc(100% - 104px)" class="overflow-hidden">
					<div class="fill-height overflow-hidden">
						<v-alert :value="error" type="error" dense text class="rounded-lg">
							{{ this.error_message }}
						</v-alert>
						<transition-group :name="transition" class="overflow-hidden">
							<div key="0" v-show="step === 0" class="fill-height pa-4">
								<Label :value="$tc('auth.Register.choose_type', 1)" />
								<RadioSelect
										v-model="item.account.type"
										clearable
										:items="types"
										@input="nextStep()"
								/>
							</div>
							<div key="1" v-show="step === 1" class="fill-height" style="overflow: hidden auto">
								<div class="pa-3 sub-title-2">{{ $tc('auth.Register.infos_a', 1) }}</div>
								<FieldGroup mode="register" v-model="item.account" :fields="account_fields" @formOk="(e) => account_ok = e" :pevents="events" />
							</div>
							<div key="2" v-show="step === 2" class="fill-height" style="overflow: hidden auto">
								<div class="pa-3 sub-title-2">{{ $tc('auth.Register.infos_c', 1) }}</div>
								<FieldGroup mode="register" v-model="item.contact" :fields="contact_fields" @formOk="(e) => contact_ok = e" :pevents="events" />
							</div>
							<div key="3" v-show="step === 3" class="fill-height" style="overflow: hidden auto">
								<div class="pa-3 sub-title-2">{{ $tc('auth.Register.infos_e', 1) }}</div>
								<FieldGroup mode="register" v-model="item.extras" :fields="extras_fields" :pevents="events" />
							</div>
							<div key="4" v-show="step === 4" class="fill-height pb-4" style="overflow: hidden auto">
								<div class="pa-3 sub-title-2">{{ $tc('auth.Register.summary', 1) }}</div>
								<div class="pt-3 px-3 paragraph">{{ $tc('auth.Register.infos_a', 1) }}</div>
								<FieldGroup v-model="item.account" :fields="account_fields" @formOk="(e) => account_ok = e" mode="readonly" :pevents="events" />
								<div class="pt-3 px-3 paragraph">{{ $tc('auth.Register.infos_c', 1) }}</div>
								<FieldGroup v-model="item.contact" :fields="contact_fields" @formOk="(e) => contact_ok = e" mode="readonly" :pevents="events" />
								<div v-if="Object.keys(extras_fields).length > 0" class="pt-3 px-3 paragraph">{{ $tc('auth.Register.infos_e', 1) }}</div>
								<FieldGroup v-if="Object.keys(extras_fields).length > 0" v-model="item.extras" :fields="extras_fields" mode="readonly" :pevents="events" />
							</div>
							<div key="5" v-show="step === 5" class="fill-height" style="overflow: hidden auto">
								<div class="text-center py-4">
									<v-icon size="40" color="success" class="mt-4">mdi-thumb-up</v-icon>
									<div class="pa-3 title-3 success--text">{{ $tc('auth.Register.congrats', 1, {contact: contact.name}) }}</div>
									<div class="pa-3 paragraph">{{ $t('auth.Register.email_send') }}<span style="font-style: italic">{{ contact.email }}</span>{{ $t('auth.Register.email_click') }}</div>
								</div>
							</div>
						</transition-group>
					</div>
				</div>
				<v-divider class="mx-3" v-if="step < 5" />
				<v-row class="mx-0" v-if="step < 5">
					<v-col class="pr-0">
						<Button medium :show="step > 0" block :label="$t('global.action.back')" :click="() => prevStep()" iconVal="mdi-arrow-left" iconPosition="left" :disabled="step === 0" />
					</v-col>
					<v-col>
						<Button medium v-if="item.account.type && account_ok === true && contact_ok === true && extras_ok === true && step > 3" block :label="$t('global.action.validate')" color="primary" :click="() => dialog = true" />
						<Button medium v-else block :label="$t('global.action.next')" color="primary" :click="() => nextStep()" :disabled="disabled" iconVal="mdi-arrow-right" iconPosition="right" />
					</v-col>
				</v-row>
			</v-card>
			<div v-if="embed" class="sub-title-2 text-center">{{ $tc('auth.Register.already_registered', 1) }}<div @click="$emit('login')" style="display: inline; width: auto; cursor: pointer; text-decoration: underline" class="primary--text ml-2">{{ $tc('auth.Login.login', 1) }}</div></div>

			<div v-else class="sub-title-2 mt-6 text-center">{{ $tc('auth.Register.already_registered', 1) }}<router-link to="/auth/login" style="width: auto; cursor: pointer; text-decoration: underline" class="primary--text ml-2">{{ $tc('auth.Login.login', 1) }}</router-link></div>
		</div>
		<div v-else>
			<v-progress-circular indeterminate size="40" color="primary"/>
		</div>

		<Dialog v-model="dialog">
			<template v-slot:content>
				<div class="sub-title-2 pa-3">{{ $t('auth.Register.dialog') }}</div>
				<v-row class="mx-0">
					<v-col>
						<Button block large :label="$t('global.action.back')" dark color="celldark" :click="() => dialog = false" />
					</v-col>
					<v-col>
						<Button :loading="loading" block large :label="$t('global.action.validate')" color="primary" :click="() => register()" />
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
	export default {
		name: "Register",
		props: {
			embed: {type: Boolean}
		},
		components: {
			FieldGroup: () => import('@/components/FieldGroup.vue'),
			RadioSelect: () => import('@/components/ui/RadioSelect.vue'),
			Label: () => import('@/components/ui/Label.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		data() {
			return {
				dialog: false,
				transition: 'slide-x-fade',
				step: 0,
				loading: false,
				error: false,
				error_message: '',
				item: {
					account: {},
					contact: {},
					extras: {}
				},
				types: [],
				account_fields: [],
				contact_fields: [],
				extras_fields: [],
				extras_ok: false,
				account_ok: false,
				contact_ok: false,
				ready: false,
				contact: {},
				events: null
			}
		},
		computed: {
			application_ready(){
				return this.$store.getters['application_ready']
			},
			disabled(){
				return this.step === 0 ? !this.item.account.type : this.step === 1 ? !this.account_ok : this.step === 2 ? !this.contact_ok : false
			}
		},
		watch: {
			application_ready(val){
				this.fetchRegisterInfos()
			}
		},
		methods: {
			nextStep() {
				this.transition = 'slide-x-fade'
				this.step++
			},
			prevStep() {
				this.transition = 'slide-x-fade-reverse'
				this.step--
			},
			fetchRegisterInfos(){
				this.$wsc.getOptions(this.$route.params, {}, 'user/register', success => {
					this.account_fields = success['account']
					this.contact_fields = success['contact']
					this.extras_fields = success['extras']

					this.extras_ok = Object.keys(this.extras_fields).length === 0

					this.types = success['account']['type'].choices

					this.$delete(this.account_fields, 'type')

					if(Object.keys(this.extras_fields).length === 0){
						this.extras_ok = true
					}

					this.ready = true
				}, fail => {

				}, {}, true)
			},
			register() {
				this.loading = true

				this.$wsc.post('user/register', this.item, success => {
					this.contact = {name: success.display_name, email: success.email}
					this.step++
					this.loading = false
					this.dialog = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.SignUp.register.success'))
				}, fail => {
					this.loading = false
					this.$store.dispatch('snackbar/error', this.$t('snackbar.SignUp.register.fail'))
				})
			}
		},
		created() {
			this.events = new this.$NVEvent('Register')

			if(this.application_ready){
				this.fetchRegisterInfos()
			}
		}
	}
</script>
